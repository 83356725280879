/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Parse from 'parse';
import * as Sentry from '@sentry/browser';

import * as Config from '../config.json';
import {
  Employee,
  IndividualAccount,
  AdminInviteParams,
  PaginatedUsers,
  User,
  AddEmployerAdminParams,
} from './types';
import { User as AdminUserType } from 'portal/admin/types';
import moment from 'moment';

export type AdminPaginatedUsers = {
  users: AdminUserType[];
  meta: PaginatedUsers['meta'];
};

export class AdminApiService {
  static async init() {
    Parse.initialize(Config.parse.appId, Config.parse.javascriptKey);
    Parse.serverURL = Config.parse.serverURL;
  }

  static async isAdmin(user: any) {
    const roleQuery = new Parse.Query(Parse.Role);
    roleQuery.equalTo('users', user);
    try {
      const roles = await roleQuery.find();
      if (roles.find((role) => role.getName() === 'super-admin')) {
        return true;
      }
    } catch (error) {
      switch (error.code) {
        case Parse.Error.INVALID_SESSION_TOKEN:
          Parse.User.logOut();
          window.location.reload();
          break;
        default:
          alert('Error ' + error.message);
          Sentry.captureException(error);
          break;
      }
    }

    return false;
  }

  static async isCompany(employer: any) {
    const Employer = Parse.Object.extend('Employer');
    const employerData = await new Parse.Query(Employer).get(employer.id);

    return employerData.get('employerType') === 'Company';
  }

  static async getUsers() {
    return Parse.Cloud.run('getAllUsers');
  }

  static async getUserEmployers(userId: string) {
    return Parse.Cloud.run('getUserEmployers', {
      userId,
    });
  }

  static async getPaginatedUsers(
    pageNumber: number,
    pageSize: number,
    username?: string,
    employerName?: string,
    firstName?: string,
    lastName?: string
  ): Promise<AdminPaginatedUsers> {
    if (Number.isNaN(pageNumber)) {
      pageNumber = 1;
    }

    if (Number.isNaN(pageSize) || pageSize > 1000 || pageSize < 50) {
      pageSize = 50;
    }

    const result: PaginatedUsers = await Parse.Cloud.run('getPaginatedUsers', {
      pageNumber,
      pageSize,
      searchBy: {
        username,
        employerName,
        firstName,
        lastName
      },
    });

    const users = result.users.map((user) => {
      const employer = user.get('employer');
      const firstName = user.get('firstname') ?? '';
      const lastName = user.get('lastname') ?? '';
      const employerPrivate = user.get('employerprivate');

      return {
        userId: user.id,
        employerName: employer?.get('name') ?? 'No employer',
        employer,
        employerPrivate,
        email: user.get('username'),
        created: user.createdAt,
        isSubCompany: user.get('sub_company') ?? false,
        userLevel: user.get('level'),
        fullName: `${firstName} ${lastName}`,
        status:
          user.get('stripeId') || employerPrivate?.get('stripeId')
            ? 'linked'
            : 'pending',
      };
    });

    return {
      ...result,
      users,
    };
  }

  static async getAllTips(begin: any, end: any, employer: any) {
    return Parse.Cloud.run('getAllTips', { begin, end, employer });
  }

  static async getAllFeedback(begin: any, end: any, employerId: any) {
    return Parse.Cloud.run('getAllFeedback', { begin, end, employerId });
  }

  static async getAllCharges(begin: any, end: any, employerId: any, limit: number ) {
    return Parse.Cloud.run('getAllCharges', { begin, end, employerId, limit });
  }

  static async createEmployer(
    name: string,
    password: string,
    tier: string,
    employerType: string,
    email: string,
    industry: string
  ) {
    await Parse.Cloud.run('createEmployer', {
      name,
      password,
      tier,
      employerType,
      email: email.toLowerCase(),
      industry,
    });
  }

  static async getEmployer(employerId: string) {
		const Employer = Parse.Object.extend('Employer');
		const employerQuery = new Parse.Query(Employer);

    return employerQuery.get(employerId);
  }

  static async createEmployerDepartment(
    name: string,
    iconType: string,
    employerId: string,
  ) {
    const Employer = Parse.Object.extend('Employer');
    const Department = Parse.Object.extend('Department');

    const employer = new Employer();
    employer.id = employerId;

    const employerDepartment = new Department();
    employerDepartment.set('employer', employer);
    employerDepartment.set('name', name);
    employerDepartment.set('iconType', iconType);

    console.log('save department..', name, employerId, employerDepartment);

    try {
      const savedDepartment = await employerDepartment.save();
      console.log('department saved', savedDepartment);
    } catch (e) {
      console.log('department save error', e);
      throw new Error(e);
    }
  }

  static async getDepartments(employerId: string) {
    const Employer = Parse.Object.extend('Employer');
    const Department = Parse.Object.extend('Department');
    const departmentQuery = new Parse.Query(Department);

    const employer = new Employer();
    employer.id = employerId;

    departmentQuery.include('employer');
    departmentQuery.doesNotExist('deletedAt');
    departmentQuery.equalTo('employer', employer);
    let departmentsForEmployer: any[] = [];

    try {
      departmentsForEmployer = await departmentQuery.find();
    } catch (e) {
      departmentsForEmployer = [];
      console.log('Could not fetch Departments', e);
      throw new Error('Could not fetch Departments');
    }

    return departmentsForEmployer;
  }

  
  static async getDepartment(departmentId: string) {
    const Department = Parse.Object.extend('Department');
    const departmentQuery = new Parse.Query(Department);

    try {
      const department = await departmentQuery.get(departmentId);
      return department;
    } catch (e) {
      console.log('Get Department did not complete sucessfully');
      throw new Error('Get Department did not complete sucessfully!');
    }
  }


  static async updateDepartment(departmentId: string, name: string, iconType?: string, employerId?: string) {
    const Employer = Parse.Object.extend('Employer');
    const Department = Parse.Object.extend('Department');
    const departmentQuery = new Parse.Query(Department);

    const tempEmployer = new Employer();
    tempEmployer.id = employerId;

    const department = await departmentQuery.get(departmentId);
    if (name) {
      department.set('name', name);
    }
    if (iconType) {
      department.set('iconType', iconType);
    } else {
      department.unset('iconType');
    }
    if (employerId) {
      department.set('employer', tempEmployer);
    }

    try {
      return await department.save();
    } catch (e) {
      console.log('Department update did not complete sucessfully');
      throw new Error('Department Update did not complete sucessfully!');
    }
  }

  // soft delete
  static async deleteDepartment(departmentId: string) {
    const Department = Parse.Object.extend('Department');
    const departmentQuery = new Parse.Query(Department);

    const department = await departmentQuery.get(departmentId);

    const currentMoment = moment().toDate();
    department.set('deletedAt', currentMoment);

    try {
      await department.save();
    } catch (e) {
      console.log('Department soft delete did not complete sucessfully');
    }
  }


  ////////////////////////
  /////////////////////////////////
  //////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////


  static async createLocation(
    name: string,
    employerId: string,
  ) {
    const Employer = Parse.Object.extend('Employer');
    const Location = Parse.Object.extend('Location');

    const employer = new Employer();
    employer.id = employerId;

    const employerLocation = new Location();
    employerLocation.set('employer', employer);
    employerLocation.set('name', name);

    console.log('save location..', name, employerId, employerLocation);

    try {
      const savedLocation = await employerLocation.save();
      console.log('location saved', savedLocation);
    } catch (e) {
      console.log('location save error', e);
      throw new Error(e);
    }
  }

  static async getLocations(employerId: string) {
    const Employer = Parse.Object.extend('Employer');
    const Location = Parse.Object.extend('Location');
    const locationQuery = new Parse.Query(Location);

    const employer = new Employer();
    employer.id = employerId;

    locationQuery.include('employer');
    locationQuery.doesNotExist('deletedAt');
    locationQuery.equalTo('employer', employer);
    let locationsForEmployer: any[] = [];

    try {
      locationsForEmployer = await locationQuery.find();
    } catch (e) {
      locationsForEmployer = [];
      console.log('Could not fetch Locations', e);
      throw new Error('Could not fetch Locations');
    }

    return locationsForEmployer;
  }

  static async getLocation(locationId: string) {
    const Location = Parse.Object.extend('Location');
    const locationQuery = new Parse.Query(Location);

    try {
      const location = await locationQuery.get(locationId);
      return location;
    } catch (e) {
      console.log('Get Location did not complete sucessfully');
      throw new Error('Get Location did not complete sucessfully!');
    }
  }


  static async updateLocation(locationId: string, name: string, employerId?: string) {
    const Employer = Parse.Object.extend('Employer');
    const Location = Parse.Object.extend('Location');
    const locationQuery = new Parse.Query(Location);

    const tempEmployer = new Employer();
    tempEmployer.id = employerId;

    const location = await locationQuery.get(locationId);
    if (name) {
      location.set('name', name);
    }
    if (employerId) {
      location.set('employer', tempEmployer);
    }

    try {
      return await location.save();
    } catch (e) {
      console.log('Location update did not complete sucessfully');
      throw new Error('Location Update did not complete sucessfully!');
    }
  }

  // soft delete
  static async deleteLocation(locationId: string) {
    const Location = Parse.Object.extend('Location');
    const locationQuery = new Parse.Query(Location);

    const location = await locationQuery.get(locationId);

    const currentMoment = moment().toDate();
    location.set('deletedAt', currentMoment);

    try {
      await location.save();
    } catch (e) {
      console.log('Department soft delete did not complete sucessfully');
    }
  }

    ////////////////////////
  /////////////////////////////////
  //////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////


  static async createProperty(
    name: string,
    employerId: string,
  ) {
    const Employer = Parse.Object.extend('Employer');
    const Property = Parse.Object.extend('Property');

    const employer = new Employer();
    employer.id = employerId;

    const employerProperty = new Property();
    employerProperty.set('employer', employer);
    employerProperty.set('name', name);

    console.log('save property..', name, employerId, employerProperty);

    try {
      const savedProperty = await employerProperty.save();
      console.log('property saved', savedProperty);
    } catch (e) {
      console.log('property save error', e);
      throw new Error(e);
    }
  }

  static async getProperties(employerId: string) {
    const Employer = Parse.Object.extend('Employer');
    const Property = Parse.Object.extend('Property');
    const propertyQuery = new Parse.Query(Property);

    const employer = new Employer();
    employer.id = employerId;

    propertyQuery.include('employer');
    propertyQuery.doesNotExist('deletedAt');
    propertyQuery.equalTo('employer', employer);
    let propertiesForEmployer: any[] = [];

    try {
      propertiesForEmployer = await propertyQuery.find();
    } catch (e) {
      propertiesForEmployer = [];
      console.log('Could not fetch Properties', e);
      throw new Error('Could not fetch Properties');
    }

    return propertiesForEmployer;
  }
  
  static async getProperty(propertyId: string) {
    const Property = Parse.Object.extend('Property');
    const propertyQuery = new Parse.Query(Property);

    try {
      const property = await propertyQuery.get(propertyId);
      return property;
    } catch (e) {
      console.log('Get Property did not complete sucessfully');
      throw new Error('Get Property did not complete sucessfully!');
    }
  }


  static async updateProperty(propertyId: string, name: string, employerId?: string) {
    const Employer = Parse.Object.extend('Employer');
    const Property = Parse.Object.extend('Property');
    const propertyQuery = new Parse.Query(Property);

    const tempEmployer = new Employer();
    tempEmployer.id = employerId;

    const location = await propertyQuery.get(propertyId);
    if (name) {
      location.set('name', name);
    }
    if (employerId) {
      location.set('employer', tempEmployer);
    }

    try {
      return await location.save();
    } catch (e) {
      console.log('Property update did not complete sucessfully');
      throw new Error('Property Update did not complete sucessfully!');
    }
  }

  // soft delete
  static async deleteProperty(propertyId: string) {
    const Property = Parse.Object.extend('Property');
    const propertyQuery = new Parse.Query(Property);

    const property = await propertyQuery.get(propertyId);

    const currentMoment = moment().toDate();
    property.set('deletedAt', currentMoment);

    try {
      await property.save();
    } catch (e) {
      console.log('Property soft delete did not complete sucessfully');
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  static async inviteAdmin(params: AdminInviteParams) {
    await Parse.Cloud.run('inviteAdmin', params);
  }

  static async addEmployerAdmin(params: AddEmployerAdminParams) {
    return await Parse.Cloud.run('addEmployerAdmin', params);
  }

  static async removeEmployerAdmin(params: AddEmployerAdminParams) {
    return await Parse.Cloud.run('removeEmployerAdmin', params);
  }

  static async deleteEmployer(employerId: string, targetUserId: string) {
    const result = await Parse.Cloud.run('deleteEmployer', {
      employerId,
      targetUserId,
    });
    if (!result) {
      alert('Something went wrong trying to delete this user');
    }
  }

  static async deleteStripeAccount(accountId: string) {
    const result = await Parse.Cloud.run('deleteStripeAccount', {
      accountId,
    });
    if (!result) {
      alert('Something went wrong trying to delete this user');
    }
  }

  static async unlinkStripeAccount(userId: string) {
    const result = await Parse.Cloud.run('unlinkStripeAccount', {
      userId,
    });
    if (!result) {
      alert('Something went wrong trying to delete this user');
    }
  }

  static async getEmployers(params: any) {
    return await Parse.Cloud.run('getEmployers', params);
  }

  static async getEmployerAdmins(employerId: string) {
    return await Parse.Cloud.run('getEmployerAdmins', {
      employerId
    });
  }

  static async getEmployerPrivateForEmployer(
    employer: Parse.Object
  ): Promise<Parse.Object | undefined> {
    const EmployerPrivate = Parse.Object.extend('EmployerPrivate');
    const employerPrivateQuery = new Parse.Query(EmployerPrivate);
    return employerPrivateQuery.equalTo('employer', employer).first();
  }

  static async getIndividualAccount(
    userId: string
  ): Promise<IndividualAccount | undefined> {
    const queryUser = new Parse.User();
    queryUser.id = userId;

    const WorkerPrivate = Parse.Object.extend('WorkerPrivate');
    const workerPrivateQuery = new Parse.Query(WorkerPrivate);
    workerPrivateQuery
      .include('user')
      .include('worker')
      .equalTo('user', queryUser);
    const workerPrivate = await workerPrivateQuery.first();

    if (!workerPrivate) {
      return;
    }

    const employee: Partial<Employee> = {
      displayname: workerPrivate.get('worker').get('displayname'),
      parseObject: workerPrivate.get('worker'),
      parsePrivateObject: workerPrivate,
    };

    const parseUser = await workerPrivate.get('user');

    const user: User = {
      username: parseUser.get('username'),
      firstname: parseUser.get('firstname'),
      lastname: parseUser.get('lastname'),
      stripeLinked: !!parseUser.get('stripeId'),
      parseObject: parseUser,
    };

    return {
      user,
      employee,
    };
  }

  static async getPaginatedTableFeatureFlag(): Promise<boolean> {
    return Parse.Cloud.run('getFeatureFlag', { name: 'new-pagination-table' });
  }

  static async setPaginatedTableFeatureFlag(): Promise<boolean> {
    return Parse.Cloud.run('setFeatureFlag', { name: 'new-pagination-table' });
  }
}
