import React, { Component, Suspense } from 'react';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import history from 'custom-history';

import { ApiService } from 'common/api-service';
import { AdminContext } from 'common/employer-context';
import { Employer } from 'common/types';
import { ErrorBoundary } from 'common/error-boundary';
import { AdminApiService } from 'common/admin-api-service';

import { DashboardPage, DashboardPageV2 } from './portal/async';
import { Landing } from './portal/async';
// import { Login } from './portal/async';
import { LoginV2 } from './portal/async';
import { LiveFeed } from './portal/async';
import { Profile } from './portal/async';
import { QrCodes } from './portal/async';

import { Register } from './portal/async';
import { LinkAccount } from './portal/async';
import { AccountLinked } from './portal/async';
import { ResetPassword } from './portal/async';
import { AdminRedirect } from './portal/async';
import { AdminDropdownManagementRedirect } from './portal/async';
import { StatusDashboard } from './portal/async';
import { BranchForm } from './portal/async';
import { BranchesPage } from './portal/async';
import { IndividualAccountPage } from './portal/async';

import { Tip } from './sender/async';
import { SenderQrProfile } from './sender/async';
import { Feedback } from './sender/async';
import { Confirmation } from './sender/async';

Sentry.init({
  dsn: 'https://ef457c7b26c142868cf6c021612b772f@sentry.io/1465016',
});
class AuthRequiredRoute extends Route<any> {
  render() {
    if (!ApiService.isUserLoggedIn()) {
      return <Redirect to="/login" />;
    } else {
      return <Route path={this.props.path} component={this.props.component} />;
    }
  }
}

export type AppState = {
  employer: Employer | undefined;
  userOrganizations: any[] | undefined;
  isAdmin: boolean;
  isLoading: boolean;
  isCompany: boolean;
  isSubCompany: boolean;
  isLevelOne: boolean;
  setEmployer(employer: Employer): void;
  setUserOrganizations(userOrganizations: any): void;
  setIsAdmin(isAdmin: boolean): void;
  setIsSubCompany(isSubCompany: boolean): void;
  setIsLevelOne(isLevelOne: boolean): void;
  refreshUserData(): void;
};

class App extends Component<unknown, AppState> {
  constructor(props: unknown) {
    super(props);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      employer: undefined,
      userOrganizations: undefined,
      setEmployer: this.setEmployer,
      isAdmin: false,
      isCompany: false,
      isSubCompany: false,
      isLevelOne: false,
      refreshUserData: this.refreshUserData.bind(this),
      setUserOrganizations: this.setUserOrganizations,
      setIsAdmin: this.setIsAdmin,
      setIsSubCompany: this.setIsSubCompany,
      setIsLevelOne: this.setIsLevelOne,
      isLoading: true,
    };
  }

  setEmployer = (employer: Employer): void => {
    console.log('setting employer...', employer);
    if (employer) {
      sessionStorage.setItem('employerId', employer.id);
      if (employer.employerPrivateId) {
        sessionStorage.setItem('employerPrivateId', employer.employerPrivateId);
      }
    } else {
      sessionStorage.removeItem('employerId');
      sessionStorage.removeItem('employerPrivateId');
    }
    this.setState({ employer });
  };

  setUserOrganizations = (userOrganizations: any): void => {
    this.setState({ userOrganizations });
  };

  setIsAdmin = (isAdmin: boolean): void => {
    this.setState({ isAdmin });
  };

  setIsSubCompany = (isSubCompany: boolean): void => {
    this.setState({ isSubCompany });
  };

  setIsCompany = (isCompany: boolean): void => {
    this.setState({ isCompany });
  };

  setIsLevelOne = (isLevelOne: boolean): void => {
    this.setState({ isLevelOne });
  };

  async refreshUserData() {
    const user = ApiService.getLoggedInUser();
    const isAdmin = await AdminApiService.isAdmin(user);

    if (!user) {
      this.setState({ isLoading: false });
    }

    if (user) {
      const userOrganizations = await AdminApiService.getUserEmployers(
        user?.id
      );

      this.setUserOrganizations(userOrganizations);
      const employerId = sessionStorage.getItem('employerId');

      if (!employerId && !this.state.isAdmin && userOrganizations?.length > 0) {
        sessionStorage.setItem('employerId', userOrganizations[0]?.id);
        sessionStorage.setItem(
          'employerPrivateId',
          userOrganizations[0]?.employerPrivateId
        );
      }
    }

    const newState: Pick<AppState, 'isAdmin' | 'isLoading' | 'employer'> = {
      isAdmin,
      isLoading: false,
      employer: undefined,
    };

    if (user && user.get('employer')) {
      const isCompany = await AdminApiService.isCompany(user.get('employer'));
      this.setIsCompany(isCompany);
      this.setIsSubCompany(user.get('sub_company') === true);
      this.setIsLevelOne(user.get('level') === 1);
    }

    const employerId = sessionStorage.getItem('employerId');
    const employerPrivateId = sessionStorage.getItem('employerPrivateId');
    if (employerId) {
      try {
        newState.employer = await ApiService.getEmployerById(
          employerId,
          employerPrivateId
        );
        console.log('Admin context employer...', newState.employer);
      } catch (_) {
        sessionStorage.removeItem('employerId');
        sessionStorage.removeItem('employerPrivateId');
      }
    }

    this.setState(newState);
  }

  async componentDidMount(): Promise<void> {
    await this.refreshUserData();
  }

  render(): React.ReactNode {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <ErrorBoundary>
        <AdminContext.Provider value={this.state}>
          <Router history={history}>
            <Suspense fallback={<div />}>
              <Switch>
                <Route path="/" exact component={Landing} />
                <Route path="/login" component={LoginV2} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/new-account" component={Register} />
                <Route path="/live/:code" component={LiveFeed} />
                <Route path="/tip" component={Tip} />
                <Route path="/qr-profile" component={SenderQrProfile} />
                <Route path="/feedback" component={Feedback} />
                <Route path="/confirmation" component={Confirmation} />
                <AuthRequiredRoute path="/admin" component={AdminRedirect} />
                <AuthRequiredRoute path="/employer-dropdowns/:employerId" component={AdminDropdownManagementRedirect} />
                <AuthRequiredRoute
                  path="/dashboard"
                  component={DashboardPage}
                />
                <AuthRequiredRoute
                  path="/dashboard-v2"
                  component={DashboardPageV2}
                />
                <AuthRequiredRoute path="/qr-codes" component={QrCodes} />
                <AuthRequiredRoute path="/settings" component={Profile} />
                <AuthRequiredRoute
                  path="/link-account"
                  component={LinkAccount}
                />
                <AuthRequiredRoute
                  path="/account-linked"
                  component={AccountLinked}
                />
                <AuthRequiredRoute path="/status" component={StatusDashboard} />
                <AuthRequiredRoute path="/branches" component={BranchesPage} />
                <AuthRequiredRoute
                  path="/individual/:id"
                  component={IndividualAccountPage}
                />
                <AuthRequiredRoute
                  path="/branch/create"
                  component={BranchForm}
                />
                <AuthRequiredRoute path="/branch/:id" component={BranchForm} />
              </Switch>
            </Suspense>
          </Router>
        </AdminContext.Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
