import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId })
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <React.Fragment>
          <h1>Sorry, something went wrong</h1>
          <p>
            Try refreshing the page or <button onClick={this.showDialog}>Report feedback</button>
          </p>
        </React.Fragment>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }

  showDialog = () => {
    Sentry.showReportDialog({ eventId: this.state.eventId })
  }
}