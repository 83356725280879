/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Employer } from './types';

// TODO: Figure out if we want to break this up into multiple contexts
export interface AdminContextProps {
  employer: Employer | undefined,
  isAdmin: boolean,
  isLoading: boolean,
  isCompany: boolean,
  isSubCompany: boolean,
  isLevelOne: boolean,
  setEmployer(employer: Employer): void;
  setIsAdmin(isAdmin: boolean): void;
  setIsSubCompany(isSubCompany: boolean): void;
  setIsLevelOne(isLevelOne: boolean): void;
  refreshUserData(): void;
}

export const AdminContext = React.createContext<AdminContextProps>({
  employer: undefined,
  isAdmin: false,
  isCompany: false,
  isSubCompany: false,
  isLevelOne: false,
  isLoading: true,
  setEmployer: (_): void => { return; },
  setIsAdmin: (_): void  => { return; },
  setIsSubCompany: (_): void => { return; },
  setIsLevelOne: (_): void => { return; },
  refreshUserData: (): void => { return; }
});