import Parse from 'parse';

export type Employer = {
  id: string;
  name: string;
  email: string;
  employerType: string;
  industry: string;
  description: string;
  link: string;
  logoUrl: string;
  stripeDeduct: boolean;
  disableWriteOnQrCode: boolean;
  disableViewTransactions: boolean;
  disableFeedbackTable: boolean;
  disableOrgSetting: boolean;
  enableWhiteLabel: boolean;
  enableCustomConfirmation: boolean;
  customConfirmationUrl: string;
  customConfirmationUrlText: string;
  isFeeMandatory: boolean;
  enableAutomatedReports: boolean;
  enableMasterQRCodes: boolean;
  masterQRCodeEmployeeSelectionLabel: string,
  masterQRCodeEmployeeDropdownTitle: string,
  masterQRCodeEmployeeSearchPlaceholder: string,
  enableCustomFeedbackField: boolean;
  enableFeedbackFieldLocation: boolean;
  enableFeedbackFieldDepartment: boolean;
  enableFeedbackFieldRoomNumber: boolean;
  enableFeedbackFieldPropertyName: boolean;
  enableFeedbackFields: boolean;
  customFeedbackFieldTitle: string;
  customFeedbackFieldLabel: string;
  parseObj: Parse.Object;
  employerPrivateId?: string;
  tier?: string;
  stripeId?: string;
  tipAmount: string;
  employerPrivateParseObj?: Parse.Object;
};

export type ScheduledReportInput = {
  // name: string;
  scheduled: boolean;
  type: string;
  email: string;
  interval: string;
  weeklyAnchor: string | null;
  monthlyAnchor: string | null;
  employerId: string;
};

export type ScheduledReport = {
  id: string;
  createdAt: string;
  name: string;
  scheduled: boolean;
  type: string;
  email: string;
  interval: string;
  intervalFrequancy: string;
  weeklyAnchor: string | null;
  monthlyAnchor: string | null;
  employer: any;
};

export type ScheduledReportHistory = {
  id: string;
  createdAt: string;
  name: string;
  scheduled: boolean;
  type: string;
  email: string;
  interval: string;
  weeklyAnchor: string | null;
  monthlyAnchor: string | null;
  employer: any;
  report: any;
  fromDate: string;
  toDate: string;
};

export type GetReportInput = {
  reportId: string;
  employerId: string;
};

export enum InternalEmployeeTypeEnum {
  Regular = 'regular',
  Pooled = 'pooled'
}

export type SharedEmployeeFields = {
  id: string;
  displayname: string;
  jobTitle: string;
  thirdLine: string;
  description: string;
  link: string;
  imageUrl: string;
  imageRatingFullUrl: string;
  imageRatingEmptyUrl: string;
  feedbackQuestion: string;
  parseObject: Parse.Object;
  directPayment?: boolean;
  directUsername?: string;
};

export type Employee = {
  qrCodeUrl: string;
  parsePrivateObject: Parse.Object;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  department: string;
  employeeId: string;
  dateOfEmployment?: Date,
  isMaster?: boolean,
  locationId?: string | null |{ id: string, name: string }
  propertyId?: string | null |{ id: string, name: string }
  departmentId?: string | null |{ id: string, name: string }
  departmentIconType?: string | null
} & SharedEmployeeFields;

export type SubEmployee = {
  qrCodeUrl: string;
} & SharedEmployeeFields;

export type PublicEmployee = {
  type?: string;
  employerId: string;
  employerLink?: string;
  employerName?: string;
  employerEmail?: string;
  locationId?: string;
  propertyId?: string;
  departmentId?: string;
  isMaster?: boolean;
  departmentParseObj?: Parse.Object,
  propertyParseObject?: Parse.Object,
  locationParseObject?: Parse.Object,
  employerEnableMasterQRCodes?: boolean,
  masterQRCodeEmployeeSelectionLabel?: string,
  masterQRCodeEmployeeDropdownTitle?: string,
  masterQRCodeEmployeeSearchPlaceholder?: string,
  employerEnableFeedbackFieldDepartment?: boolean,
  employerEnableFeedbackFieldLocation?: boolean,
  employerEnabledFeedbackFieldPropertyName?: boolean,
  employerEnableFeedbackFieldRoomNumber?: boolean,
  employerParseObj: Parse.Object;
  hideDeductModal: boolean;
  qrCodeUrl?: string;
} & SharedEmployeeFields;

export interface PostReviewParams {
  employee: PublicEmployee;
  employeeRating?: number;
  hotelRating?: number;
  comments?: string;
  customField?: string;
  roomNumber?: string | number | undefined;
  departmentId?: string,
  locationId?: string,
  propertyId?: string
}

export type Branch = {
  id: string;
  name: string;
  address: string;
  employer: Parse.Object;
  user: Parse.Object;
};

export interface EmployeeInviteParams {
  email: string;
  displayName: string;
  employerId: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  employeeId: string;
}

export interface AdminInviteParams {
  email: string;
  employerId: string;
  firstName: string;
  lastName: string;
}
export interface AddEmployerAdminParams {
  userId: string;
  employerId: string;
}

export type ChargeType = {
  id: string;
  chargeId: string;
  employerId: string;
  employeeId: string | undefined;
  payeeId: string;
  amount: number;
  netAmount: number;
  fee: number;
  feeCovered: boolean;
  payeeName: string;
  employerName: string;
  date: number;
};

export type RatingType = {
  name: string;
  rating: number;
  comment: string;
  date: Date;
};

export type User = {
  id?: string;
  email?: string;
  username: string;
  firstname?: string;
  lastname?: string;
  stripeLinked: boolean;
  parseObject: Parse.User;
};

export interface IndividualAccount {
  user: User;
  employee: Partial<Employee>;
}

export type RecaptchaConfirm = {
  confirm(verificationCode: string): unknown;
  sessionInfo: string;
};

export type Currency = '' | 'usd' | 'cad' | 'eur' | 'gbp';

export type UserSchema = {
  username: string;
  firstname?: string;
  lastname?: string;
  level?: number;
  stripeId?: string;
  employerprivate?: Parse.Object<Parse.Attributes>;
  employer: Parse.Object<Employer>;
  sub_company?: any; // TODO: deprecate
};

export type PaginationMeta = {
  count: number;
  page: number;
  total: number;
  links: {
    self: string | null;
    first: string | null;
    last: string | null;
    prev: string | null;
    next: string | null;
  };
};

export type PaginatedUsers = {
  users: Parse.User<UserSchema>[];
  meta: PaginationMeta;
};

export enum EmployerDropdownType {
  Department,
  Location,
  Property
}

export type EmployerDropdownDto = {
  id: string,
  name: string,
  type: EmployerDropdownType
}