import { lazy } from 'react';

const Tip = lazy(() => import(/* webpackChunkName: "send-tip" */ './index').then((module) => ({default: module.Tip})));
const TipWorkerPicker = lazy(() => import(/* webpackChunkName: "send-tip" */ './index').then((module) => ({default: module.TipWorkerPicker})));
const Feedback = lazy(() => import(/* webpackChunkName: "send-tip" */ './index').then((module) => ({default: module.Feedback})));
const Confirmation = lazy(() => import(/* webpackChunkName: "send-tip" */ './index').then((module) => ({default: module.Confirmation})));
const SenderQrProfile = lazy(() => import(/* webpackChunkName: "send-tip" */ './index').then((module) => ({default: module.SenderQrProfile})));

export {
  Tip,
  TipWorkerPicker,
  Feedback,
  Confirmation,
  SenderQrProfile
};